<script setup lang="ts">
  const grayZone = ref<number>(0)
  const stickyBannerHeight = ref<number>(0)
  const numberOfStickyBanners = ref<number>(0)

  const bannerSlots = useBannerSlots()
  const { UseOutsiderBanner } = bannerSlots

  onNuxtReady(() => {
    setTimeout(() => {
      const contentStore = useContentStore()
      const { contentHeight } = contentStore

      grayZone.value = 1400
      stickyBannerHeight.value = 2500

      numberOfStickyBanners.value = Math.max(
        0,
        Math.floor((contentHeight - grayZone.value) / stickyBannerHeight.value)
      )
    }, 300)
  })

  const marginTopStyle = computed(() => {
    return {
      marginTop: `${grayZone.value}px`,
    }
  })
</script>

<template>
  <div class="sticky-banner-content bp-main-grid full-content relative">
    <div
      class="col-span-2 col-start-1 row-start-1 hidden lg:grid"
      :style="marginTopStyle"
    >
      <div class="relative" v-for="stickyBanner in numberOfStickyBanners">
        <div class="sticky top-[150px]">
          <UseOutsiderBanner />
        </div>
      </div>
    </div>

    <div class="full-content bp-main-grid lg:full-content row-start-1">
      <slot />
    </div>
    <div
      class="col-span-2 col-start-13 row-start-1 hidden lg:grid"
      :style="marginTopStyle"
    >
      <div class="relative" v-for="stickyBanner in numberOfStickyBanners">
        <div class="sticky top-[150px]">
          <UseOutsiderBanner />
        </div>
      </div>
    </div>
  </div>
</template>
